import axios from 'axios';
import { type iIPApiComResponse } from '~/@types';
import { type LocationResponse, RequestStatus, rescoverApi } from '@rescover/ui-library';

let cachedLocation: LocationResponse | undefined = undefined;

/**
 * Retrieve the current user's geo location
 * @returns location object or undefined if not found or error occured.
 */
export async function findMyLocation(): Promise<LocationResponse | undefined> {
   if (cachedLocation) {
      if (DEBUG) console.info('Location from local cache');
      return cachedLocation;
   }

   try {
      const resp = await rescoverApi.api.geo.location();
      if (resp.data.status == RequestStatus.Success)
         return cachedLocation = resp.data;
   }
   catch (err) {
      if (DEBUG) console.error('Failed to find current location via primary', err);
   }

   try {
      console.warn('Failed to find current location, trying backup method.');
      const resp = await axios.get<iIPApiComResponse>(`https://pro.ip-api.com/json?fields=500&key=${import.meta.env.VITE_IP_API_KEY}`);
      if (resp.status < 300)
         return cachedLocation = {
            status: RequestStatus.Success,
            postalCode: resp.data.zip,
            latitude: resp.data.lat,
            longitude: resp.data.lon,
            cities: {
               en: resp.data.city
            },
            locale: 'en',
            stateIso: resp.data.region,
            timeZone: resp.data.timezone,
         };
   }
   catch (err) {
      console.error('Failed to find current location via backup', err);
   }

   return undefined;
}
